import axios from 'axios';
import { Product } from 'src/models/product.model';
import { OrderLine } from 'src/models/order-line.model';
import { formatPrice } from 'src/utilities/helpers';

const state = {
  products: [],
  orderLines: [],
  productCodes: [],
  totalProductsCount: 0,
  shoppingCartInitizalized: false,
  loadingOrderLines: false,
  validatingStock: false,
  shoppingCartTotals: {
    CouponsTax: { price: '€ 0,00', rawPrice: 0 },
    CouponsNet: { price: '€ 0,00', rawPrice: 0 },
    CouponsTotal: { price: '€ 0,00', rawPrice: 0 },
    OrderCostsTax: { price: '€ 0,00', rawPrice: 0 },
    OrderCostsNet: { price: '€ 0,00', rawPrice: 0 },
    OrderCostsTotal: { price: '€ 0,00', rawPrice: 0 },
    ShippingCostsTax: { price: '€ 0,00', rawPrice: 0 },
    ShippingCostsNet: { price: '€ 0,00', rawPrice: 0 },
    ShippingCosts: { price: '€ 0,00', rawPrice: 0 },
    OrderLinesTotalTax: { price: '€ 0,00', rawPrice: 0 },
    OrderLinesTotalNet: { price: '€ 0,00', rawPrice: 0 },
    OrderLinesTotal: { price: '€ 0,00', rawPrice: 0 },
    OrderTotal: { price: '€ 0,00', rawPrice: 0 },
    //  BESPOKE DEPVIEW-38
    OrderVatTotal: { price: '€ 0,00', rawPrice: 0 }
    // END BESPOKE DEPVIEW-38
  },
  shoppingCartValid: false,
  shoppingCartEmpty: false,
  minimumOrderQuantityPrice: '€ 0,00',
  minimumOrderQuantityRawPrice: 0,
  orderLinesTotalPrice: '€ 0, 00',
  orderLinesTotalRawPrice: 0,

  invalidOrderLines: [],
  totalOrderLines: 0,
  stockValid: false,
  couponCode: '',
  hasCoupon: false,
  shoppingCartTotalsFormat: [],
  shoppingCartControlTotalsFormat: [],
  // BESPOKE
  isDeletingOrderLinesInBulk: false,
  isOrderingProducts: false
  // END BESPOKE
};

const getters = {
  productCodes (state) {
    return state.productCodes;
  },
  orderLines (state) {
    return state.orderLines;
  },
  totalProductsCount (state) {
    return state.totalProductsCount;
  },
  loadingOrderLines (state) {
    return state.loadingOrderLines;
  },
  validatingStock (state) {
    return state.validatingStock;
  },
  shoppingCartInitizalized (state) {
    return state.shoppingCartInitizalized;
  },
  productInformationEndpoint (state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.productInformationEndpoint;
    const client = rootGetters.clientCode;
    const language = rootGetters.language;
    return `${endpoint}/${client}?language=${language}`;
  },
  shoppingCartTotals (state) {
    return state.shoppingCartTotals;
  },
  shoppingCartTotalsFormat (state) {
    return state.shoppingCartTotalsFormat;
  },
  shoppingCartControlTotalsFormat (state) {
    return state.shoppingCartControlTotalsFormat;
  },
  stockValid (state, getters, rootState, rootGetters) {
    if (!rootGetters.stockLimit) {
      return true;
    } else {
      return state.stockValid;
    }
  },
  invalidOrderLines (state) {
    return state.invalidOrderLines;
  },
  shoppingCartEmpty (state) {
    return state.shoppingCartEmpty;
  },
  orderLinesTotal (state) {
    return state.shoppingCartTotals.OrderLinesTotal;
  },
  minimumOrderQuantity (state) {
    return state.minimumOrderQuantity;
  },
  minimumOrderQuantityPrice (state) {
    return state.minimumOrderQuantityPrice;
  },
  minimumOrderQuantityRawPrice (state) {
    return state.minimumOrderQuantityRawPrice;
  },
  orderLinesTotalPrice (state) {
    return state.orderLinesTotalPrice;
  },
  orderLinesTotalRawPrice (state) {
    return state.orderLinesTotalRawPrice;
  },
  minimumOrderQuantityValid (state, getters, rootState, rootGetters) {
    if (rootGetters.useMinimumOrderQuantity) {
      return getters.orderLinesTotalRawPrice - getters.minimumOrderQuantityRawPrice > 0;
    } else {
      return true;
    }
  },
  couponCode (state) {
    return state.couponCode;
  },
  hasCoupon (state) {
    return state.hasCoupon;
  },
  showCheckoutButton (state, getters) {
    return getters.stockValid && getters.minimumOrderQuantityValid;
  },
  // BESPOKE
  isDeletingOrderLinesInBulk(state) {
    return state.isDeletingOrderLinesInBulk;
  },
  isOrderingProducts(state) {
    return state.isOrderingProducts;
  }
  // END BESPOKE
};

const mutations = {
  initConfig (state, config) {
    state.shoppingCartTotalsFormat = config.shoppingCartTotalsFormat;
    state.shoppingCartControlTotalsFormat = config.shoppingCartControlTotalsFormat;
  },
  setProductCodes (state, productCodes) {
    state.productCodes = productCodes;
  },
  setProducts (state, products) {
    const orderLines = [...state.orderLines];
    orderLines.forEach(orderLine => {
      orderLine.setProduct(products.filter(product => product.id === orderLine.productId)[0]);
      orderLine.setComputedQuantity();
      // BESPOKE VIEW
      // Also add the product information to orderLines that have been grouped
      // as shoppingcart products under the mainOrderLine product, but since we
      // will only display the prod code and title, omit setting computed quantities
      orderLine.shoppingCartProducts.forEach(shoppingCartProduct => {
        shoppingCartProduct.setProduct(products.filter(product => product.id === shoppingCartProduct.productId)[0]);
      });
      // END BESPOKE VIEW
    });

  },
  setOrderLines (state, orderLines) {
    // BESPOKE VIEW
    // Find configurator products, and group them together with their corresponding orderlines
    var mainProductOrderLines = orderLines.filter(orderLine => orderLine.customFields.isMainProduct === 'True');
    
    mainProductOrderLines.forEach(mainProductOrderLine => {
      orderLines.forEach(orderLine => {
        if (orderLine.customFields.mainProductLineId === mainProductOrderLine.lineId) {
          mainProductOrderLine.shoppingCartProducts.push(orderLine); 
        }
      });
    });

    // Add orderlines to the view that are not a configproduct or part of a config product
    var singleProducts = orderLines.filter((orderLine) => {
      return orderLine.customFields.isMainProduct === undefined && orderLine.customFields.mainProductLineId === undefined;
    });
    mainProductOrderLines.concat(singleProducts);

    var mergedOrderLines = mainProductOrderLines.concat(singleProducts);

    state.orderLines = mergedOrderLines;
    state.totalOrderLines = mergedOrderLines.length;
    // END BESPOKE VIEW
  },
  setLoadingOrderLines (state, loading) {
    state.loadingOrderLines = loading;
  },
  setValidatingStock (state, validating) {
    state.validatingStock = validating;
  },
  addToCart (state, productRow) {
    state.shoppingCartRows.push(productRow);
  },
  // BESPOKE VLINT
  // We present the total products not based on the 
  // shoppingcart api response, but based on the orderlines
  // and its custom fields, because configurator products
  // are grouped together
  setTotalAndCount (state, orderLines) {
    var total = 0;
    var orderLinesToCalculateQuantity = orderLines ? orderLines : state.orderLines;
    orderLinesToCalculateQuantity.forEach((orderLine) => {
      if (orderLine.customFields.mainProductLineId === undefined) {
        total += orderLine.quantity;
      }
    });
    state.totalProductsCount = total;
  },
  setShoppingCartEmpty (state, empty) {
    state.shoppingCartEmpty = empty;
  },
  setProductPrice (state, productWithPrice) {
    state.orderLines.forEach((orderLine) => {
      if (orderLine.productId === productWithPrice.id) {
        // BESPOKE DEPVIEW-53
        // If product i a custom glass product, change the original product price
        // to the custom calculated price from the API
        if (orderLine.customFields.isCustomGlassProduct) {
          var priceObj = {
            price: orderLine.customFields.glassPrice,
            basePrice: orderLine.customFields.glassPrice,
            isSalesAction: false,
            quantity: 1
          }


          productWithPrice.setPrices([priceObj]);
        }
        // END BESPOKE DEPVIEW-53
        orderLine.product.prices = productWithPrice.prices;
        orderLine.setOrderLineTotal();
      }
    });
  },
  setProductStock (state, productWithStock) {
    state.orderLines.forEach(orderLine => {
      if (orderLine.productId === productWithStock.id) {
        orderLine.product.setStock(productWithStock.stock);
        orderLine.setComputedQuantity();
      }
    });
  },
  updateOrderLineQuantity (state, { lineId, quantity, unitCode, totalCount, showPrices }) {
    let index = state.orderLines.findIndex(orderLine => orderLine.lineId === lineId);
    state.orderLines[index].quantity = quantity;
    state.orderLines[index].unitCode = unitCode;

    // update total orderline quantities
    let prodCode = state.orderLines[index].productId;
    state.orderLines.forEach(orderLine => {
      if (orderLine.productId === prodCode) {
        orderLine.totalOrderLinesQuantity = totalCount;
      }
    });
    if (showPrices) {
      state.orderLines[index].setOrderLineTotal();
    }
    state.orderLines[index].setComputedQuantity();
  },
  updateOrderLineComment (state, { lineId, comment }) {
    let index = state.orderLines.findIndex(orderLine => orderLine.lineId === lineId);
    state.orderLines[index].comments = comment;
  },
  deleteOrderLine (state, { orderLineIndex, totalCount }) {
    const orderLines = [ ...state.orderLines ];
    let prodCode = state.orderLines[orderLineIndex].productId;
    state.orderLines.forEach(orderLine => {
      if (orderLine.productId === prodCode) {
        orderLine.totalOrderLinesQuantity = totalCount;
      }
    });
    orderLines.splice(orderLineIndex, 1);
    state.orderLines = orderLines;
  },
  setShoppingCartTotals (state, totals) {
    let totalsObj = {};
    Object.keys(totals).forEach(key => {
      totalsObj[key] = { price: formatPrice(totals[key]), rawPrice: totals[key] };
    });
    // BESPOKE DEPVIEW-38
    // Add an entry for all values combined
    var totalVat = totals.ShippingCostsTax - totals.CouponsTax + totals.OrderCostsTax + totals.OrderLinesTotalTax;
    totalsObj.OrderVatTotal = { price: formatPrice(totalVat), rawPrice: totalVat }
    // END BESPOKE DEPVIEW-38
    state.shoppingCartTotals = totalsObj;
    state.minimumOrderQuantityPrice = formatPrice(totals.MinimumOrderQuantity);
    state.minimumOrderQuantityRawPrice = totals.MinimumOrderQuantity;
    state.orderLinesTotalPrice = formatPrice(totals.OrderLinesTotal);
    state.orderLinesTotalRawPrice = totals.OrderLinesTotal;
  },
  setCouponCode (state, { CouponCode, HasCoupon }) {
    state.couponCode = CouponCode;
    state.hasCoupon = HasCoupon;
  },
  setShoppingCartInitizalized (state, intitialized) {
    state.shoppingCartInitizalized = intitialized;
  },
  checkStockState (state) {
    const orderLines = [...state.orderLines];
    let invalidLines = [];
    orderLines.forEach(orderLine => {
      if (orderLine.product.customBooleans.STOCK_PRODUCT) {
        if (orderLine.product.stock.stockTotal < orderLine.totalOrderLinesQuantity) {
          invalidLines.push(orderLine);
        }
      }
    });
    if (invalidLines.length) {
      state.stockValid = false;
      state.invalidOrderLines = invalidLines;
    } else {
      state.stockValid = true;
      state.invalidOrderLines = [];
    }
  },
  emptyShoppingCart (state) {
    state.orderLines = [];
    state.totalProductsCount = 0;
  },
  // BESPOKE
  isDeletingOrderLinesInBulk(state, loading) {
    state.isDeletingOrderLinesInBulk = loading;
  },
  isOrderingProducts(state, isOrdering) {
    state.isOrderingProducts = isOrdering;
  }
  // END BESPOKE
}

const actions = {
  initShoppingCart ({ getters, commit, dispatch }) {
    dispatch('getOrderLines');
    commit('setShoppingCartInitizalized', true);
  },
  getShoppingCartTotals ({ commit, rootGetters, dispatch }) {
    axios.post(rootGetters.shoppingCartTotalsEndpoint, {})
      .then(res => {
        const data = res.data.d.Totals;
        commit('setShoppingCartTotals', data.Prices);
        dispatch('renderCheckoutButton');
        if (rootGetters.useCoupons) {
          commit('setCouponCode', data.CouponInfo);
        }
      });
  },
  getShoppingCartTotalAndCount ({ commit, rootGetters, getters }) {
    axios.post(rootGetters.shoppingCartTotalAndCountEndpoint, {})
      .then(res => {
        const data = res.data.d;
        if (data.Status === 'Success') {
          // BESPOKE
          var orderLines = data.Lines.map(orderLine => new OrderLine(orderLine));
          commit('setTotalAndCount', orderLines);          
          // END BESPOKE
        } else {
          // TODO ERROR HANDLING
        }
      });
  },
  getOrderLines ({ commit, dispatch, rootGetters }) {
    commit('setLoadingOrderLines', true);
    axios.post(rootGetters.orderLinesEndpoint, {})
      .then(res => {
        const data = res.data.d;
        if (data.Status === 'Success') {
          dispatch('getShoppingCartTotals');
          if (data.Lines.length) {
            const productCodes = data.Lines.map(orderLine => orderLine.ProductId);
            commit('setShoppingCartEmpty', false);
            commit('setProductCodes', productCodes);
            commit('setOrderLines', data.Lines.map(orderLine => new OrderLine(orderLine)));
            dispatch('getProductInformation');
          } else {
            commit('setShoppingCartEmpty', true);
            dispatch('renderCheckoutButton');
          }
        } else if (data.Status === 'Failure') {
          dispatch('renderCheckoutButton');
          window.updateErrorMessage(data.ErrorMessage);
        }
        commit('setLoadingOrderLines', false);
      });
  },
  getProductInformation ({ getters, rootGetters, commit, dispatch }) {
    axios.post(getters.productInformationEndpoint, getters.productCodes)
      .then(res => {
        const products = res.data.map(product => new Product(product));
        commit('setProducts', products);        
        
        var filteredProducts = getters.orderLines.map(orderLine => orderLine.product);
        if (rootGetters.showStock) {

          dispatch('getProductStock', filteredProducts);
        } else {
          products.forEach(product => { product.setStock({ stockTotal: 0 }); })
        }

        if (rootGetters.showPrices) {
          dispatch('getProductPrices', filteredProducts);
        }
      });
  },
  validateShoppingCartStock ({ commit, getters, rootGetters, dispatch }) {
    commit('setValidatingStock', true);
    let counter = 0; let orderLinesLength = getters.orderLines.length;
    const products = getters.orderLines.map(orderLine => orderLine.product);
    products.forEach(product => {
      axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
        .then(res => {
          counter++;
          product.setStock(res.data.d);
          commit('setProductStock', product);
          if (counter === orderLinesLength && rootGetters.stockLimit) {
            commit('checkStockState');
            if (getters.invalidOrderLines.length) {
              const message = window.vue.translations.message.message_invalid_stock_orderlines;
              const offset = window.$('#stock-warning').offset().top - 75;
              window.updateErrorMessage(message);
              window.scrollTo({ top: offset, behavior: 'smooth' });
              dispatch('renderCheckoutButton');
            } else {
              dispatch('checkout');
            }
            commit('setValidatingStock', false);
          }
        });
    });
  },
  getProductStock ({ rootGetters, getters, commit, dispatch }, products) {
    let counter = 0; let orderLinesLength = getters.orderLines.length;
    products.forEach(product => {
      axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
        .then(res => {
          counter++;
          product.setStock(res.data.d);
          commit('setProductStock', product);
          if (counter === orderLinesLength && rootGetters.stockLimit) {
            commit('checkStockState');
            dispatch('renderCheckoutButton');
          }
        });
    });
  },
  getProductPrices ({ commit, getters, dispatch, rootGetters }, products) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode

    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];

          const pricesObj = [];
          Object.keys(price.volumes).forEach(key => {
            pricesObj.push({
              price: price.volumes[key].price,
              basePrice: price.volumes[key].basePrice,
              isSalesAction: price.volumes[key].isSalesAction,
              quantity: Math.round(parseInt(key))
            });
          });

          product.setPrices(pricesObj);
          commit('setProductPrice', product);
        });
      });
  },
  updateOrderLineQuantity ({ rootGetters, commit, dispatch }, orderLine) {
    console.log(orderLine);
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.updateOrderLineEndpoint, { orderLineToBeUpdatedObj: orderLine })
        .then(res => {
          const data = res.data.d;
          if (data.Status === 'Success') {
            commit('updateOrderLineQuantity', {
              lineId: data.UpdatedLine.LineId,
              quantity: parseInt(data.UpdatedLine.Quantity),
              unitCode: data.UpdatedLine.UnitCode,
              totalCount: data.UpdatedLine.TotalCount,
              showPrices: rootGetters.showPrices
            });
            commit('setShoppingCartTotals', data.Totals.Prices);
            dispatch('getShoppingCartTotalAndCount');

            if (rootGetters.stockLimit) {
              commit('checkStockState');
            }

            dispatch('renderCheckoutButton');

            resolve(true);
          } else {
            reject();
            window.updateErrorMessage(data.ErrorMessage);
            dispatch('getOrderLines');
          }
        });
    });
  },
  updateOrderLineComment ({ rootGetters, commit }, orderLine) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.updateOrderLineEndpoint, { orderLineToBeUpdatedObj: orderLine })
        .then(res => {
          const data = res.data.d;
          if (data.Status === 'Success') {
            commit('updateOrderLineComment', { lineId: data.UpdatedLine.LineId, comment: data.UpdatedLine.Comments });
            resolve();
          } else {
            reject();
            window.updateErrorMessage(data.ErrorMessage);
          }
        });
    });
  },
  deleteOrderLine ({ rootGetters, getters, commit, dispatch }, orderLineId) {
    const orderLineIndex = getters.orderLines.findIndex(orderLine => orderLine.lineId === orderLineId);
    const orderLine = getters.orderLines[orderLineIndex];
    
    var orderLinesToBeDeleted = [];

    orderLinesToBeDeleted.push({
      LineId: orderLine.lineId,
      ShoppingCartId: orderLine.shoppingCartId,
      ProductId: orderLine.productId,
      Comments: orderLine.comments,
      Quantity: orderLine.quantity,
      UnitCode: orderLine.unitCode,
      IsFree: orderLine.isFree
    });


    var totalOrderLinesDeleted = 0;
    if (orderLine.customFields.isMainProduct === 'True') {

      commit('isDeletingOrderLinesInBulk', true);

      orderLine.shoppingCartProducts.forEach(shoppingCartProduct => {
        orderLinesToBeDeleted.push({
          LineId: shoppingCartProduct.lineId,
          ShoppingCartId: shoppingCartProduct.shoppingCartId,
          ProductId: shoppingCartProduct.productId,
          Comments: shoppingCartProduct.comments,
          Quantity: shoppingCartProduct.quantity,
          UnitCode: shoppingCartProduct.unitCode,
          IsFree: shoppingCartProduct.isFree
        })
      });

      orderLinesToBeDeleted.forEach(orderLineToBeDeleted => {

        axios.post(rootGetters.deleteOrderLineEndpoint, { lineToBeDeletedObj: orderLineToBeDeleted }).then(res => {
          const data = res.data.d;
          if (data.Status === 'Success') {
            totalOrderLinesDeleted++;

            if (totalOrderLinesDeleted >= orderLinesToBeDeleted.length) {
              commit('setShoppingCartTotals', data.Totals.Prices);
              commit('deleteOrderLine', {
                orderLineIndex: orderLineIndex,
                totalCount: data.LineToBeDeleted.TotalCount
              });

              
              // BESPOKE VIEW
              // Set total and count based on the orderlines customfields
              // instead of the totalOrderLines count
              // var orderLines = getters.orderLines.map(orderLine => { 
              //   orderLine.CustomFields = {...orderLine.customFields };
              //   return orderLine;
              // });
              commit('setTotalAndCount');
              // END BESPOKE VIEW

              if (getters.orderLines.length === 0) {
                commit('setShoppingCartEmpty', true);
              }
              
              commit('isDeletingOrderLinesInBulk', false);
            }
          } else if (data.Status === 'Failure') {
            window.updateErrorMessage(data.ErrorMessage);
          }
        });
          

      });
    }
    // If orderline is not  configured product with underlying shoppingcart products, remove the products the regular way
    else {
      axios.post(rootGetters.deleteOrderLineEndpoint, { lineToBeDeletedObj: orderLinesToBeDeleted[0] }).then(res => {
        const data = res.data.d;
        if (data.Status === 'Success') {
          commit('deleteOrderLine', {
            orderLineIndex: orderLineIndex,
            totalCount: data.LineToBeDeleted.TotalCount
          });

           // BESPOKE
          commit('setTotalAndCount');          
           // END BESPOKE
           
          commit('setShoppingCartTotals', data.Totals.Prices);
          if (rootGetters.stockLimit) {
            commit('checkStockState');
            dispatch('renderCheckoutButton');
          }
          if (getters.orderLines.length === 0) {
            commit('setShoppingCartEmpty', true);
          }
        } else if (data.Status === 'Failure') {
          window.updateErrorMessage(data.ErrorMessage);
        }
      });
    }
    
  },
  applyCoupon ({ rootGetters, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.addCouponEndpoint, { couponCode: payload })
        .then(res => {
          const response = res.data.d;
          const status = response.Status;
          if (status === 'Success') {
            commit('setCouponCode', { CouponCode: payload, HasCoupon: true });
            commit('setShoppingCartTotals', response.Totals.Prices);
            resolve(response.Message);
          } else if (status === 'CouponUsed' || status === 'NotFound') {
            commit('setCouponCode', { CouponCode: '', HasCoupon: false });
            reject(response.Message);
          }
        });
    });
  },
  removeCoupon ({ rootGetters, getters, commit }) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters.removeCouponEndpoint, { couponCode: getters.couponCode })
        .then(res => {
          const response = res.data.d;
          if (response.Status === 'Success') {
            commit('setCouponCode', { CouponCode: '', HasCoupon: false });
            commit('setShoppingCartTotals', response.Totals.Prices);
            resolve(response.Message);
          } else if (response.Status === 'Failure') {
            window.updateErrorMessage(response.Message);
            reject();
          }
        });
    });
  },
  emptyShoppingCart ({ rootGetters, commit }) {
    axios.post(rootGetters.emptyShoppingCartEndpoint, {}).then(res => {
      const data = res.data.d;
      if (data.Status === 'Success') {
        commit('emptyShoppingCart');
        commit('setShoppingCartEmpty', true);
        window.updateOrderMessage(data.Message);
      } else if (data.Status === 'Failure') {
        window.updateErrorMessage(data.Message);
      }
    });
  },
  renderCheckoutButton ({ getters }) {
    if (!getters.showCheckoutButton) {
      window.$('.checkout-button').addClass('disabled');
    } else {
      window.$('.checkout-button').removeClass('disabled');
    }
  },
  hidePageElements () {
    window.$('.vuejs-hook').hide();
  },
  checkout () {
    window.$('.uc_payment .inputbutton').click();
  },
  addToCart ({ rootGetters, dispatch }, payload) {
    let endpoint = rootGetters.orderProductEndpoint;
    let userLoggedOn = rootGetters.userLoggedOn;
    const products = Array.isArray(payload) ? payload : [payload];
    return new Promise((resolve, reject) => {
      axios.post(endpoint, JSON.stringify({ products: products, loggedInStatus: userLoggedOn })).then(
        res => {
          const response = res.data.d;
          if (response.errorMessages) {
            if (response.errorMessages === 'log in status changed') {
              window.location.replace('/webshop/login.aspx?RedirectUrl=' + window.location.pathname + window.location.search);
            } else {
              window.updateErrorMessage(response.errorMessages);
            }
          } else {
            resolve(true);
            dispatch('getShoppingCartTotalAndCount');

            // REFERS TO FUNCTION CALLS IN CUSTOM.JS
            if (response.errorMessages) {
              window.updateErrorMessage(response.errorMessages);
            }

            var message = response.order_message;
            if (response.totalProductsAdded > 0) {
              window.updateOrderMessage(message);
            }
            // END CUSTOM.JS FUNCTION CALLS
          }
        })
        .catch(err => {
          // @TODO Error handling
          reject(err);
        });
    });
  },
  // BESPOKE VIEW
  // Changed the logic to update the orderline with customfields after the product has been added to the cart
  addToCartConfigurator ({ rootGetters, commit, dispatch }, { payload, shoppingcartProducts, configuratorFields, sawListUrl, glassWidth, glassHeight }) {
    
    const products = Array.isArray(payload) ? payload : [payload];

    return new Promise((resolve, reject) => {
      
      commit('isOrderingProducts', true);

      // Get product information for shoppingcart products, since we need 
      // their corresponding units to send in the order product payload
      var orderSuccessFullMessage = "";
      var productCodes = Object.keys(shoppingcartProducts);
      var productInformationEndpoint = `${rootGetters.productInformationEndpoint}/${rootGetters.clientCode}?language=${rootGetters.language}`;
      
      axios.post(productInformationEndpoint, productCodes).then(res => {

        var productInformationResponse = res.data;                      
        var orderProducts = [];

        
        // Add the main product first to the order product payload
        // In the prod_comment we temporarily add "mainProductPendingEnrichment"
        // so that we can find it in the respsonse of adding products to cart,
        // after which we need to call updateOrderLine to enrich the customfields
        // with additional data, and then we will clear the comment again
        orderProducts.push({
          prod_code: payload.prod_code,
          ord_qty: payload.ord_qty,
          unit_code: payload.unit_code,
          prod_comment: 'mainProductPendingEnrichment',
          customFields: {}
        });

        // Add the underlying shoppingcart products of the main product to the 
        // add to cart payload. We temporarily add pendingEnrichment as a prod_comment
        // so that once we receive the response from add to cart we can find the 
        // orderlines again to enrich their customFields with the lineId of the main product
        // That way we can group the shoppingcart products with the main product in the shoppingcart overview
        productCodes.forEach(prodCode => {
          
          var selectedUnitCode = "";
          var product = productInformationResponse.filter(prodInfo => prodInfo.id === prodCode)[0];
          var selectedUnitCode;
        
          if (product.units != null) {
            var unitCode = product.units.reduce((previous, current) => {
              return current.quantity > previous.quantity ? current : previous;
            });
            selectedUnitCode = unitCode.code;
          }
          
          orderProducts.push({
            prod_code: prodCode,
            ord_qty: shoppingcartProducts[prodCode].toString(),
            unit_code: selectedUnitCode,
            prod_comment: "pendingEnrichment",
            customFields: {}
          });
        });

        // Order the main product and underlying shoppingcart products        
        var orderProductEndpoint = rootGetters.orderProductEndpoint;
        var userLoggedOn = rootGetters.userLoggedOn;

        axios.post(orderProductEndpoint, JSON.stringify({ products: orderProducts, loggedInStatus: userLoggedOn }))
          .then(res => {
            const response = res.data.d;

            orderSuccessFullMessage = response.order_message;

            if (response.errorMessages) {
              if (response.errorMessages === 'log in status changed') {
                window.location.replace('/webshop/login.aspx?RedirectUrl=' + window.location.pathname + window.location.search);
              } else {
                window.updateErrorMessage(response.errorMessages);
              }
            } else {
              
              var orderLines = response.orderLines.Lines.filter(orderLine => orderLine.Comments === 'pendingEnrichment' || orderLine.Comments === 'mainProductPendingEnrichment');
              var mainProduct = orderLines.filter(orderLine => orderLine.Comments === 'mainProductPendingEnrichment')[0];
              var mainProductLineId = mainProduct.LineId;

              var orderLinesToUpdateWithCustomFields = [];
              // Create a new orderline payload so that we can update all the 
              // custom fields of the products that were just added
              orderLines.forEach(orderLine => {

                var orderLineToUpdate = {
                  IsFree: orderLine.IsFree,
                  LineId: orderLine.LineId,
                  ProductId: orderLine.ProductId,
                  Quantity: orderLine.Quantity,
                  UnitCode: orderLine.UnitCode
                };

                // If the product is the main product, add a boolean
                // and the underlying shoppingcart products in the customFields
                if (orderLine.Comments === 'mainProductPendingEnrichment') {
                  orderLineToUpdate.CustomFields = {
                    isMainProduct: true,
                    shoppingcartProducts: JSON.stringify(shoppingcartProducts),
                    configuratorFields: JSON.stringify(configuratorFields),
                    glassWidth: glassWidth,
                    glassHeight: glassHeight,
                    sawListUrl: sawListUrl
                  }                  
                  
                } 
                // If the product is not the main product, add the main products
                // shoppingcart lineId, so that we can group the products together 
                // with the main product later in the shoppingcart overview
                else if (orderLine.Comments === 'pendingEnrichment') {
                  orderLineToUpdate.CustomFields = {
                    mainProductLineId: mainProductLineId
                  }

                }
                  
                // Empty comments, as this was only temporarily used while adding products to cart,
                // and not needed anymore after updating the orderlines with new custom fields
                // But we do add the glass dimensions to the order comment of the specific glass product
                // that is always part of the configured product 
                if (orderLine.ProductId === '33.1GLASMAATWERK') {
                  var annotation = window.vue.translations.label.label_width_height;
                  orderLineToUpdate.Comments = `${glassWidth} x ${glassHeight} ${ annotation }`
                } 
                // DEPVIEW-21
                // Add configfields to the main orderline comment
                else if (orderLine.Comments === 'mainProductPendingEnrichment') {
                  var mainProductComment = "";

                  Object.keys(configuratorFields).forEach(key => {
                    mainProductComment += key + ": ";
                    mainProductComment += configuratorFields[key] + ", "
                  });
                  
                  var dimensionLabel = window.vue.translations.label.label_glass_dimensions;
                  var glassDimensions = `${glassWidth} x ${glassHeight}`;
                  mainProductComment += `${dimensionLabel}: ${glassDimensions}`;
                  orderLineToUpdate.Comments = mainProductComment;
                }
                else {
                  orderLineToUpdate.Comments = "";
                }
                
                orderLinesToUpdateWithCustomFields.push(orderLineToUpdate);
              });
              
              var updateMultipleOrderLinesEndpoint = "/Webshop/WebshopAPI/ShoppingCartWebmethod.aspx/UpdateMultipleLines";
              
              axios.post(updateMultipleOrderLinesEndpoint, JSON.stringify({ lines: orderLinesToUpdateWithCustomFields }))
                .then(res => {
                  var status = res.data.d.Status;
                  if (status === 'Success') {
                    window.updateOrderMessage(orderSuccessFullMessage);
                    resolve(true);
                    commit('isOrderingProducts', false);
                  }
                  // Set the new totals in the shoppingcart control
                  axios.post(rootGetters.shoppingCartTotalAndCountEndpoint, {}).then(res => {
                    const data = res.data.d;
                    if (data.Status === 'Success') {                
                       // BESPOKE
                       var orderLines = data.Lines.map(line => new OrderLine(line));
                      commit('setTotalAndCount', orderLines); 
                      // END BESPOKE  
                    }
                  });
                });
            }
          });
    });
    });
  }
  // END BESPOKE VIEW
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
