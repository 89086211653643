import { formatPrice, calculatePrice } from 'src/utilities/helpers';

export class Product {
  constructor (product) {
    if (product === undefined) {
      this.setEmptyProduct();
    } else {
      this.setProduct(product);
      if (this.images) {
        this.images.unshift(this.imageUrl);
      } else {
        this.images = [this.imageUrl];
      }
    }
  }

  setProduct (product) {
    this.id = product.id;
    this.shortDescription = product.shortDescription;
    this.subTitle = product.subTitle;
    this.longDescription = product.longDescription;
    this.properties = product.properties;
    this.targetUrl = product.targetUrl;
    this.imageUrl = product.imageUrl;
    this.images = product.images;
    this.brand = product.brand;
    this.units = product.units;
    this.saleUnit = product.saleUnit;
    this.discountGroup = product.discountGroup;
    this.prices = [];
    this.units = this.setUnits(product.units);
    this.stock = { stockTotal: null };
    this.variantKey = product.variantKey;
    this.productVariants = [];
    this.customStrings = product.customStrings;
    this.customDecimals = product.customDecimals;
    this.customBooleans = product.customBooleans;
    this.customDateTimes = product.customDateTimes;
    this.includedInCustomerLists = [];
    this.imageAltText = product.id;
    this.imageTitleText = product.id;
    this.productImageBanners = [
      { position: 'top-left', values: [], show: false },
      { position: 'top-right', values: [], show: false },
      { position: 'bottom-right', values: [], show: false },
      { position: 'bottom-left', values: [], show: false }
    ];
  }

  setEmptyProduct () {
    this.id = 'NOTLOADED';
    this.shortDescription = '';
    this.subTitle = '';
    this.longDescription = '';
    this.properties = '';
    this.targetUrl = '';
    this.imageUrl = '';
    this.images = [];
    this.brand = '';
    this.variantKey = '';
    this.units = [];
    this.saleUnit = '';
    this.discountGroup = '';
    this.prices = [];
    this.units = null;
    this.stock = { stockTotal: null };
    this.variantKey = '';
    this.productVariants = [];
    this.customStrings = {};
    this.customDecimals = {};
    this.customBooleans = {};
    this.customDateTimes = {};
    this.includedInCustomerLists = [];
    this.imageAltText = '';
    this.imageTitleText = '';
  }

  // BESPOKE
  setProductTitle(product) {
    return product.subTitle !== "" ? product.subTitle : product.shortDescription;    
  }
  // END BESPOKE

  setStock (stock) {
    this.stock = stock;
  }

  setSeoInformation (seoInfo) {
    this.imageAltText = seoInfo.ImageAltText;
    this.imageTitleText = seoInfo.ImageTitleText;
  }

  setProductImageBanners (banners) {
    banners.forEach(banner => {
      this.productImageBanners[banner.Location].values.push(banner);
      this.productImageBanners[banner.Location].show = true;
    });
  }

  setUnits (units) {
    if (units) {
      return units.sort((a, b) => (a.quantity > b.quantity) ? 1 : -1)
    } else return null;
  }

  setProductVariants (productVariants) {
    this.productVariants = productVariants;
  }

  setCustomerLists (customerList) {
    this.includedInCustomerLists = customerList;
  }

  setPrices (prices) {

    const getters = window.vuexStore.$store.getters;

    if (prices !== undefined) {
      let formattedPrices = [];
      prices.forEach(priceObj => {        
        let priceIncl = null;
        let basePriceIncl = null;
        
        if (getters.priceType === 3) {
          let vatPerc = 1 + this.customDecimals.VAT_PERCENTAGE / 100;
          priceIncl = formatPrice(priceObj.price * vatPerc);
          basePriceIncl = formatPrice(priceObj.price * vatPerc);
        }
        
        formattedPrices.push({
          rawPrice: calculatePrice(priceObj.price),
          rawBasePrice: calculatePrice(priceObj.basePrice),
          price: formatPrice(priceObj.price),
          basePrice: formatPrice(priceObj.basePrice),
          priceIncl: priceIncl,
          basePriceIncl: basePriceIncl,
          quantity: priceObj.quantity === 0 ? 1 : priceObj.quantity,
          isSalesAction: priceObj.isSalesAction
        });

        // Add prices sales banner to product image banners
        if (priceObj.isSalesAction) {
          const salesBanner = getters.saleBannerColors;
          this.productImageBanners[0].show = true;

          this.productImageBanners[0].values.unshift({
            Text: salesBanner.text,
            Color: salesBanner.color,
            TextColor: salesBanner.textColor,
            Location: 0,
            Type: 0
          });
        }
      });
      this.prices = formattedPrices;
    }
  }
}
