<template>
  <div class="cmp-product-configurator" 
    :class="{ 
      'processing': processingOrderProduct,
      'loading': loadingConfiguredProduct == true || loadingDoorConfigurations == true 
      }">

    <template v-if="loadingConfiguredProduct || loadingDoorConfigurations">
    </template>
    <div class="input-row">
      <label for="system-type" class="input-label" v-translation="{ code: 'label_system_type', type: 'label' }"></label>
      <select class="input-field" id="system-type" v-model="selectedSystemType" @change="updatePanelConfig()">
        <option v-for="systemType in systemTypes" :key="systemType.key" :value="systemType.value">{{ systemType.value }}</option>
      </select>
    </div>
    <div class="input-row">
      <div class="input-annotation">
        <label for="constr-width" class="input-label" v-translation="{ code: 'label_constr_width', type: 'label' }"></label>        
        <div class="annotation">(1600mm - 7000mm)</div>
      </div>
      <div class="input-group input-field">
        <input class="input-field" id="constr-width" type="number" v-model="constructionWidth" @change="updateWidth()" v-on:keyup.enter="$event.target.blur()">
        <span class="input-mm">mm</span>
      </div>      
    </div>
    <div class="input-row">
      
      <div class="input-annotation">
        <label for="constr-height" class="input-label" v-translation="{ code: 'label_constr_height', type: 'label' }"></label>              
        <div class="annotation">(1650mm - 2500mm)</div>
      </div>
      <div class="input-group input-field">
        <input id="constr-height" type="number" v-model="constructionHeight" @change="updateHeight()" v-on:keyup.enter="$event.target.blur()">
        <span class="input-mm">mm</span>
      </div>      
    </div>
    <div class="input-row">
      <label for="total-panels" class="input-label" v-translation="{ code: 'label_totalpanels', type: 'label' }"></label>
      <select id="total-panels" class="input-field" v-model="selectedSinglePanelConfig" @change="changeNumberOfPanels()">
        <option v-for="panelNumber in singlePanelConfig" :key="panelNumber" :value="panelNumber">{{ panelNumber }}</option>
      </select>
    </div>
    <div class="input-row panel-dist">
      <label class="input-label" v-translation="{ code: 'label_panels_dist', type: 'label' }"></label>
      <div class="left">
        <select id="panels-left" class="input-field" v-model="selectedLeftPanels" @change="updatePanelDistribution('left')">
          <option v-for="panelLeft in panelsLeft" :key="panelLeft" :value="panelLeft">{{ panelLeft + " " + labelLeft }}</option>
        </select>
      </div>
      <div class="right">
        <select id="panels-right" class="input-field" v-model="selectedRightPanels" @change="updatePanelDistribution('right')">
          <option v-for="panelRight in panelsRight" :key="panelRight" :value="panelRight">{{ panelRight + " " + labelRight }}</option>
        </select>
      </div>
    </div>
    <div class="input-row">
      <label for="color" class="input-label" v-translation="{ code: 'label_color', type: 'label' }"></label>
      <select id="color" class="input-field" v-model="selectedColor" @change="updatePanelConfig()">
        <option v-for="color in colors" :key="color.key" :value="color.value">{{ color.value }}</option>
      </select>
    </div>

    <div class="viewline-totals">
      <div class="input-row">
        <span class="input-label" v-translation="{ code: 'label_total_price', type: 'label' }"></span>
        <template v-if="loadingConfiguredProduct">
          <span class="loader"></span>
        </template>
        <template v-else>          
          <utlz-product-price v-if="configuredProduct !== null" :prices="configuredProduct.prices[0]"></utlz-product-price>
        </template>
      </div>
    </div>
    
    <div class="order-viewline">
      <!-- <utlz-quantity-buttons
        :initialQuantity="1"
        :allowZeroQuantity="false"
        :quantityFactor="1"
        @quantityChanged="quantityChanged($event)">
      </utlz-quantity-buttons> -->
      <a class="button theme-primary  add-to-cart-btn" @click="orderProduct()">
        <template v-if="processingOrderProduct">          
          <span class="loader"></span>
        </template>
        <template v-else>          
          <i aria-hidden="true" class="uws-icon uws-cart"></i>
        </template>
        <span v-translation="{ type: 'button', code: 'button_order' }"></span>
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import QuantityButtons from 'src/components/webshop/order-product/blocks/QuantityButtons.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import { getProductInformation } from 'src/services/product-service';
import { Product } from 'src/models/product.model';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    'utlz-quantity-buttons': QuantityButtons,
    'utlz-product-price': ProductPrice
  },
  data() {
    return {
      orderQuantity: 1,
      configuredProduct: null,
      shoppingcartProducts: null,
      configuredProductPrice: 0,
      selectedSystemType: '',
      selectedSinglePanelConfig: 0,
      selectedLeftPanels: 0,
      selectedRightPanels: 0,
      selectedColor: '',      
      singlePanelConfig: [],
      panelsLeft: [],
      panelsRight: [],
      constructionWidth: 1600,
      constructionHeight: 1650,
      minConstructionWidth: 1600,
      maxConstructionWidth: 7000,
      minConstructionHeight: 1650,
      maxConstructionHeight: 2500,
      colors: [
      ],    
      systemTypes: [
      ],
      loadingDoorConfigurations: false,
      loadingConfiguredProduct: false,
      processingOrderProduct: false,
      glassWidth: 0,
      glassHeight: 0
    }
  },
  computed: {
    ...mapGetters(['language']),
    ...mapGetters(['bespokeEndpoints', 'updateOrderLineEndpoint']),  
    ...mapGetters('shoppingCart', ['orderLines']),
    labelLeft() {
      return window.vue.translations.label.label_panels_left;
    },
    labelRight() {
      return window.vue.translations.label.label_panels_right;
    },
    viewlineConfigEndpoint() {      
      var host = this.bespokeEndpoints.viewlineHost;
      var width = this.constructionWidth;
      var height = this.constructionHeight;
      var panelsLeft = this.selectedLeftPanels;
      var panelsRight = this.selectedRightPanels;
      var color = this.colors.filter(color => color.value === this.selectedColor)[0].key;
      var type = this.systemTypes.filter(system => system.value === this.selectedSystemType)[0].key;

      var endpoint = `${host}/viewline?constructionHeight=${height}&constructionWidth=${width}&panelsLeft=${panelsLeft}&panelsRight=${panelsRight}&color=${color}&type=${type}`;
      return endpoint;
    },
    viewlineDoorsEndpoint() {      
      var host = this.bespokeEndpoints.viewlineHost;
      return `${host}/viewline/doors?constructionWidth=${this.constructionWidth}`; 
    },
    viewlineColorsEndpoint() {
      return `${this.bespokeEndpoints.viewlineHost}/viewline/colors?language=${this.language}`; 
    },
    viewlineSystemTypesEndpoint() {
      return `${this.bespokeEndpoints.viewlineHost}/viewline/types?language=${this.language}`; 
    }
  },
  methods: {
    ...mapActions('shoppingCart', ['addToCartConfigurator']),
    updateWidth() {
      var width = parseInt(this.constructionWidth);
      if (width > this.maxConstructionWidth) {
        this.constructionWidth = this.maxConstructionWidth;
      } else if (width < this.minConstructionWidth) {
        this.constructionWidth = this.minConstructionWidth;
      }
      this.updatePanelConfig();
    },
    updateHeight() {
      var height = parseInt(this.constructionHeight);
      if (height > this.maxConstructionHeight) {
        this.constructionHeight = this.maxConstructionHeight;
      } else if (height < this.minConstructionHeight) {
        this.constructionHeight = this.minConstructionHeight;
      }      
      this.updateConfiguredProduct();
    },
    updatePanelDistribution(side) {
      if (side === 'left') {
        this.selectedRightPanels = this.selectedSinglePanelConfig - this.selectedLeftPanels;
      } else {
        this.selectedLeftPanels = this.selectedSinglePanelConfig - this.selectedRightPanels;
      }
      this.updateConfiguredProduct();
    },
    changeNumberOfPanels() {
      this.updateNumberOfPanels();
      this.updateConfiguredProduct();
    },
    updateNumberOfPanels() {
        this.panelsLeft = [0, this.selectedSinglePanelConfig];
        this.panelsRight = [0, this.selectedSinglePanelConfig];
        
        if (this.selectedRightPanels != 0) {
          this.selectedRightPanels = this.selectedSinglePanelConfig;
          this.selectedLeftPanels = 0;
        } else {
          this.selectedLeftPanels = this.selectedSinglePanelConfig;
          this.selectedRightPanels = 0;
        }
    },
    getConfigColorAndTypes() {
      return new Promise((resolve) => {
        axios.get(this.viewlineColorsEndpoint).then(colors => {
          this.colors = colors.data;
          this.selectedColor = this.colors[0].value;

          axios.get(this.viewlineSystemTypesEndpoint).then(types => {
            this.systemTypes = types.data;
            this.selectedSystemType = this.systemTypes[0].value;
            resolve(true);
          })
        });
      });
      
    },
    updatePanelConfig() {
      this.loadingDoorConfigurations = true;

      axios.get(this.viewlineDoorsEndpoint).then(res => {
        this.singlePanelConfig = res.data.single;

        // If a panel config was previously changed by the user, restore its state
        if (!this.singlePanelConfig.includes(this.selectedSinglePanelConfig)) {
          this.selectedSinglePanelConfig = this.singlePanelConfig[0]; 
        }
            
        this.updateNumberOfPanels();
        this.loadingDoorConfigurations = false;        
        this.updateConfiguredProduct();
      });
    },    
    updateConfiguredProduct() {
      this.loadingConfiguredProduct = true;
      axios.get(this.viewlineConfigEndpoint).then(res => {
        
        this.glassWidth = res.data.glassWidth
        this.glassHeight = res.data.glassHeight;
        
        var productId = res.data.orderProduct;
        var shoppingcartProducts = res.data.shoppingcartProducts;

        getProductInformation({ 
          prodCodes: [productId], 
          getStock: false, 
          getPrices: true, 
          getCustomerLists: false,
          getSeoInformation: false }).then(res => {

            res.retrievedPrices.then(resPrices => {                            
              this.configuredProduct = res.products[0];           
              //this.configuredProductPrice = this.configuredProduct.prices[0].rawPrice; 
            });

            this.shoppingcartProducts = shoppingcartProducts;
            this.loadingConfiguredProduct = false;
          })

      })
    },
    quantityChanged(newQuantity) {
      this.orderQuantity = newQuantity.quantity;
    },
    orderProduct() {
      this.processingOrderProduct = true;
      var payload = {
        prod_code: this.configuredProduct.id,
        ord_qty: this.orderQuantity,
        unit_code: this.configuredProduct.units !== null ? this.configuredProduct.units[0].code : "",
        prod_comment: '',
        customFields: {}
      };

      var configuratorFields = {
        [window.vue.translations.label.label_system_type]: this.selectedSystemType,
        [window.vue.translations.label.label_constr_width]: this.constructionWidth,
        [window.vue.translations.label.label_constr_height]: this.constructionHeight,
        [window.vue.translations.label.label_totalpanels]: this.selectedSinglePanelConfig,
        [window.vue.translations.label.label_doors_left]: this.selectedLeftPanels,
        [window.vue.translations.label.label_doors_right]: this.selectedRightPanels,
        [window.vue.translations.label.label_color]: this.selectedColor
      }

      var sawListUrl = this.getSawListUrl(
        this.constructionWidth, 
        this.constructionHeight, 
        this.selectedLeftPanels, 
        this.selectedRightPanels, 
        this.selectedColor, 
        this.selectedSystemType
      );

      var orderProductPayload = { 
        payload: payload, 
        shoppingcartProducts: this.shoppingcartProducts, 
        configuratorFields: configuratorFields,
        glassWidth: this.glassWidth,
        glassHeight: this.glassHeight,
        sawListUrl: sawListUrl
      };

      this.addToCartConfigurator(orderProductPayload).then(res => {
        this.processingOrderProduct = false;
      });
    },
    getSawListUrl(constrWidth, constrHeight, panelsLeft, panelsRight, color, type) {
      var colorKey = this.colors.filter(availColor => availColor.value === color)[0].key;
      var systemTypeKey = this.systemTypes.filter(systemType => systemType.value === type)[0].key;
      return `${this.bespokeEndpoints.viewlineHost}/viewline/sawlist?constructionHeight=${constrHeight}&constructionWidth=${constrWidth}&panelsLeft=${panelsLeft}&panelsRight=${panelsRight}&color=${colorKey}&type=${systemTypeKey}`;
    }
  },
  beforeMount() {
    this.loadingDoorConfigurations = true;
    this.getConfigColorAndTypes().then(_ => {      
      this.loadingDoorConfigurations = false;
      this.updatePanelConfig();
    });
  },
  created() {
  }
}
</script>

<style>

</style>