<template>
  <div class="cmp-product-configurator cmp-windshield-configurator"
       :class="{
      'processing': processingOrderProduct,
      'loading': loadingConfiguredProduct == true || loadingDoorConfigurations == true
      }">
    <template v-if="loadingConfiguredProduct || loadingDoorConfigurations">
    </template>

    <div class="input-row">
      <label for="profile-color" class="input-label" v-translation="{ code: 'label_color_profile', type: 'label' }"></label>
      <select class="input-field" id="profile-color" v-model="selectedProfileColor" @change="updateWindShieldProduct()">
        <option v-for="profileColor in profileColors" :key="profileColor.colorCode" :value="profileColor.colorName">{{ profileColor.colorName }}</option>
      </select>
    </div>

    <div class="input-row">
      <label for="glass-color" class="input-label" v-translation="{ code: 'label_color_glass', type: 'label' }"></label>
      <select class="input-field" id="glass-color" v-model="selectedGlassColor" @change="updateWindShieldProduct()">
        <option v-for="glassColor in glassColors" :key="glassColor.colorCode" :value="glassColor.colorName">{{ glassColor.colorName }}</option>
      </select>
    </div>

    <div class="input-row">
      <div class="input-annotation">
        <label for="windshield-width" class="input-label" v-translation="{ code: 'label_width_windshield', type: 'label' }"></label>
        <div class="annotation">(500mm - 2000mm)</div>
      </div>
      <div class="input-group input-field">
        <input class="input-field" id="windshield-width" type="number" v-model="windShieldWidth" @change="updateWindShieldWidth()" v-on:keyup.enter="$event.target.blur()">
        <span class="input-mm">mm</span>
      </div>
    </div>


    <div class="viewline-totals">
      <div class="input-row">
        <span class="input-label" v-translation="{ code: 'label_total_price', type: 'label' }"></span>
        <template v-if="loadingConfiguredProduct">
          <span class="loader"></span>
        </template>
        <template v-else>
          {{ formattedTotalPrice }}
        </template>
      </div>
    </div>

    <div class="order-viewline">
      <!-- <utlz-quantity-buttons
        :initialQuantity="1"
        :allowZeroQuantity="false"
        :quantityFactor="1"
        @quantityChanged="quantityChanged($event)">
      </utlz-quantity-buttons> -->
      <a class="button theme-primary  add-to-cart-btn" @click="orderWindShield()">
        <template v-if="processingOrderProduct">
          <span class="loader"></span>
        </template>
        <template v-else>
          <i aria-hidden="true" class="uws-icon uws-cart"></i>
        </template>
        <span v-translation="{ type: 'button', code: 'button_order' }"></span>
      </a>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { formatPrice } from 'src/utilities/helpers';
  import QuantityButtons from 'src/components/webshop/order-product/blocks/QuantityButtons.vue';
  import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
  import { getProductInformation } from 'src/services/product-service';
  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      'utlz-quantity-buttons': QuantityButtons,
      'utlz-product-price': ProductPrice
    },
    data() {
      return {
        orderQuantity: 1,
        selectedProfileColor: '',
        selectedGlassColor: '',
        windShieldWidth: 500,
        minWindShieldWidth: 500,
        maxWindShieldWidth: 2000,
        totalProductPrice: '',
        profileColors: [],
        glassColors: [],
        profileSetId: '',
        glassId: '',
        glassPrice: 0,
        profileSetPrice: 0,
        profileSetProduct: null,
        formattedTotalPrice: "",
        loadingDoorConfigurations: false,
        loadingConfiguredProduct: false,
        processingOrderProduct: false
      }
    },
    computed: {
      ...mapGetters(['language']),
      ...mapGetters(['bespokeEndpoints', 'updateOrderLineEndpoint']),
      ...mapGetters('shoppingCart', ['orderLines']),
      profileColorsEndpoint() {
        // TODO
        // FIx languages
        return `${this.bespokeEndpoints.viewlineHost}/windbreaker/profilesetcolors?language=${this.language}`;
      },
      glassColorsEndpoint() {
        return `${this.bespokeEndpoints.viewlineHost}/windbreaker/glasscolors?language=${this.language}`;
      },
      windShieldEndpoint() {
        var host = this.bespokeEndpoints.viewlineHost;
        var width = this.windShieldWidth;
        var glassColorCode = this.glassColors.filter(glassColor => glassColor.colorName === this.selectedGlassColor)[0].colorCode;
        var profileColorCode = this.profileColors.filter(profileColor => profileColor.colorName === this.selectedProfileColor)[0].colorCode;
        return `${this.bespokeEndpoints.viewlineHost}/windbreaker/wbconfig?width=${width}&profilecolor=${profileColorCode}&glasscolor=${glassColorCode}`;
      }
    },
    methods: {
      ...mapActions('shoppingCart', ['addToCartConfigurator', 'addToCart']),
      updateWindShieldWidth() {
        var width = parseInt(this.windShieldWidth);        
        this.windShieldWidth = width;
        if (width > this.maxWindShieldWidth) {
          this.windShieldWidth = this.maxWindShieldWidth;
        } else if (width < this.minWindShieldWidth) {
          this.windShieldWidth = this.minWindShieldWidth;
        }
        this.updateWindShieldProduct();
      },
      getProfileAndGlassColors() {
        return new Promise((resolve) => {
          axios.get(this.profileColorsEndpoint).then(colors => {
            this.profileColors = colors.data;
            this.selectedProfileColor = this.profileColors[0].colorName;

            axios.get(this.glassColorsEndpoint).then(types => {
              this.glassColors = types.data;
              this.selectedGlassColor = this.glassColors[0].colorName;
              resolve(true);
            })
          });
        });
      },
      updateWindShieldProduct() {
        this.loadingConfiguredProduct = true;

        axios.get(this.windShieldEndpoint).then(res => {
          this.profileSetId = res.data.profileSetId;
          this.glassId = res.data.glassId;

          getProductInformation({
            prodCodes: [this.profileSetId],
            getStock: false,
            getPrices: true,
            getCustomerLists: false,
            getSeoInformation: false
          }).then(prodRes => {

            prodRes.retrievedPrices.then(resPrices => {
              this.profileSetProduct = prodRes.products[0];
              this.glassPrice = res.data.glassPlatePrice;
              this.profileSetPrice = this.profileSetProduct.prices[0].rawPrice;

              var totalPrice = 2 * this.glassPrice + this.profileSetPrice;
              this.formattedTotalPrice = formatPrice(totalPrice);
            });
            this.loadingConfiguredProduct = false;
          })

        })
      },
      quantityChanged(newQuantity) {
        this.orderQuantity = newQuantity.quantity;
      },
      orderWindShield() {
        this.processingOrderProduct = true;
        var shoppingCartProducts = [];

        shoppingCartProducts.push({
        prod_code: this.profileSetId,
        ord_qty: 1,
        unit_code: this.profileSetProduct.units !== null ? this.profileSetProduct.units[0].code : "",
        prod_comment: `${ window.vue.translations.label.label_width_windshield_comment }${ this.windShieldWidth }mm`,
        customFields: {
            isWindshieldProfileSet: "true"
          }
        });

        // Deduct 55 from the windshield width for in the comment as stated in DEPVIEW-54
        shoppingCartProducts.push({
          prod_code: this.glassId,
          ord_qty: 2,
          unit_code: "STUK",
          prod_comment: `Glasmaat: 8mm x 1000mm x ${this.windShieldWidth - 55} mm`,
          customFields: {
            isCustomGlassProduct: "true",
            glassPrice: this.glassPrice
          }
        });

        this.addToCart(shoppingCartProducts).then(res => {
          this.processingOrderProduct = false;
        });
      }
    },
    beforeMount() {
      this.loadingDoorConfigurations = true;

      this.getProfileAndGlassColors().then(_ => {
        this.loadingDoorConfigurations = false;
        this.updateWindShieldProduct();
      });
    },
    created() {
    }
  }
</script>

<style>
</style>
